import React from 'react'
import Layout from '../../../components/layout'
import GetTheAppComponent from "../../../components/pages";

const embedded = true;
const GetTheAppPageEmbedded = () => (
  <Layout embedded={embedded}>
    <GetTheAppComponent embedded={embedded} webViewVersion="v1" />
  </Layout>
)

export default GetTheAppPageEmbedded
